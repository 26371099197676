









































import { Action, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { IUserWithAbilities } from '@/api';

@Component<Account>({
  metaInfo: {
    title: 'Account',
  },
})
export default class Account extends Vue {
  protected subscribedNews = false;

  @Getter
  protected me!: IUserWithAbilities;

  @Getter
  protected profileImage!: string;

  @Action
  protected deleteMe!: () => void;
}
