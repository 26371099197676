

























import { Action, Getter, Mutation } from 'vuex-class';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { ICreateSubscriberParams, IPage } from '@/api';
import { IToast } from '@/store/modules/toast';
import rules from '@/utils/validation';
import { VuetifyForm } from '@/plugins/vuetify';

@Component
export default class DialogSubscriberCreate extends Vue {
  protected rules = rules;

  @Ref() protected readonly form!: VuetifyForm;

  protected isOpen = false;

  protected email = '';

  @Getter
  protected page!: IPage;

  @Action
  protected createSubscriber!: (data: ICreateSubscriberParams) => Promise<void>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  protected async onSubscribe() {
    if (!this.form.validate()) {
      return;
    }

    try {
      await this.createSubscriber({
        page: this.page._id,
        email: this.email,
      });
      this.setToast({ message: 'New subscriber added successfully.' });
      this.closeDialog();
    } catch (e) {
      this.setToast({ message: 'Failed to add subscriber.', type: 'error' });
    }
  }

  protected closeDialog() {
    this.isOpen = false;
    this.form.reset();
  }
}
