import { GetterTree, Module, MutationTree } from 'vuex';
import { get } from '@/utils/vuex';
import { IRootState } from '@/store';

export interface ISettingsState {
  darkMode: boolean;
}

const state: ISettingsState = {
  darkMode: window.localStorage.getItem('darkMode') === '1',
};

const getters: GetterTree<ISettingsState, IRootState> = {
  darkMode: get('darkMode'),
};

const mutations: MutationTree<ISettingsState> = {
  toggleDarkMode: (state: ISettingsState) => {
    state.darkMode = !state.darkMode;
    if (state.darkMode) {
      window.localStorage.setItem('darkMode', '1');
    } else {
      window.localStorage.removeItem('darkMode');
    }
  },
};

const module: Module<ISettingsState, IRootState> = {
  namespaced: false,
  state,
  getters,
  mutations,
};

export default module;
