

















import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IEvent, ObjectId } from '@/api';

@Component
export default class Event extends Vue {
  @Prop(Object)
  protected event!: IEvent;

  @Getter
  protected darkMode!: boolean;

  @Action
  protected deleteEvent!: (id: ObjectId) => void;

  protected gotoEvent() {
    const { page: pageId, _id: eventId } = this.event;
    this.$router.push({ name: 'page/event', params: { pageId, eventId } });
  }
}
