var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-6"},[_c('div',{staticClass:"header-main d-flex align-center pa-4 py-2"},[_c('router-link',{staticClass:"brand mr-6 font-weight-bold",attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/logo.svg"),"svg-inline":""}}),_vm._v(" PageFate ")]),(_vm.me)?_c('page-select'):_vm._e(),_c('v-spacer'),(_vm.me)?[(_vm.page)?[(_vm.page)?_c('v-btn',{attrs:{"target":"_blank","href":_vm.page.url,"color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-open-in-new")]),_vm._v(" Visit Page ")],1):_vm._e()]:_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"42"}},[_vm._v("mdi-account-circle")])],1)]}}],null,false,3141458347)},[_c('v-list',[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Logged in as "),_c('b',{domProps:{"textContent":_vm._s(_vm.me.email)}})])],1),_c('v-list-item',{attrs:{"to":{ name: 'account' }}},[_c('v-list-item-title',[_vm._v("Account Settings")])],1),_c('v-list-item',{attrs:{"to":{ name: 'logout' }}},[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1)]:_vm._e()],2),(_vm.page)?_c('div',{staticClass:"header-sub d-flex align-center justify-center"},_vm._l((_vm.navigationItems),function(ref){
var icon = ref.icon;
var to = ref.to;
var text = ref.text;
return _c('router-link',{key:text,staticClass:"d-inline-flex flex-column pa-5 text-center",staticStyle:{"min-width":"120px"},attrs:{"to":to(_vm.page)}},[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}}),_c('span',{staticClass:"pt-2",domProps:{"textContent":_vm._s(text)}})],1)}),1):_vm._e(),_c('v-progress-linear',{attrs:{"indeterminate":_vm.isLoading,"color":_vm.isLoading ? '#1b2a4e' : 'rgba(0,0,0,0)'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }