



























































































import { Action, Getter, Mutation } from 'vuex-class';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { EVENT_STATUS_RESOLVED, getStatusMetadata, INCIDENT_STATUS } from '@/constants';
import { IComponent, ICreateOrUpdateEventAffectedComponent, IUpdate, StatusType } from '@/api';
import capitalize from 'lodash/capitalize';
import { ICreateOrUpdateUpdateData } from '@/store/modules/update';
import { IToast } from '@/store/modules/toast';
import { DateTime as LDateTime } from 'luxon';
import map from 'lodash/map';
import rules from '@/utils/validation';
import { VuetifyForm } from '@/plugins/vuetify';

interface IStatusItem {
  value: string;
  text: string;
}

@Component
export default class DialogEventUpdateEdit extends Vue {
  protected readonly rules = rules;
  protected isOpen = false;
  protected isDatePickerOpen = false;
  protected isTimePickerOpen = false;
  protected isFormValid = false;
  protected date = '';
  protected time = '';
  protected status = '';
  protected body = '';
  protected affectedComponents: ICreateOrUpdateEventAffectedComponent[] = [];

  @Ref()
  protected readonly form!: VuetifyForm;

  @Prop()
  protected update!: IUpdate;

  @Getter
  protected components!: IComponent[];

  @Action
  protected updateUpdate!: (data: ICreateOrUpdateUpdateData) => Promise<IUpdate>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  @Watch('isOpen', { immediate: true })
  protected onIsOpenChanged() {
    if (this.isOpen) {
      this.setUpdate();
    }
  }

  @Watch('update', { immediate: true })
  protected onUpdateChanged() {
    this.setUpdate();
  }

  @Watch('status', { immediate: true })
  protected async onStatusChanged() {
    await this.$nextTick();
    this.form?.validate();
  }

  protected setUpdate() {
    const occuredAt = LDateTime.fromISO(this.update.occurredAt);
    this.date = occuredAt.toFormat('yyyy-MM-dd');
    this.time = occuredAt.toFormat('HH:mm');
    this.status = this.update.status;
    this.body = this.update.body;
    this.affectedComponents = this.update.affectedComponents;
  }

  protected get isStatusResolved() {
    return this.status === EVENT_STATUS_RESOLVED;
  }

  protected get statusItems(): IStatusItem[] {
    return map(INCIDENT_STATUS, (s) => ({ value: s, text: capitalize(s) }));
  }

  protected get placeholderMessage() {
    return getStatusMetadata(this.status as StatusType).placeholder;
  }

  protected get formattedDate() {
    return this.date ? LDateTime.fromFormat(this.date, 'yyyy-MM-dd').toLocaleString(LDateTime.DATE_MED) : '';
  }

  protected get formattedTime() {
    return this.time ? LDateTime.fromFormat(this.time, 'H:m').toFormat('T') : '';
  }

  protected async onConfirm() {
    const date = LDateTime.fromFormat(this.date, 'yyyy-MM-dd');
    const time = LDateTime.fromFormat(this.time, 'H:m');
    const occurredAt = date.set({ hour: time.hour, minute: time.minute }).toISO();
    try {
      this.updateUpdate({
        updateId: this.update._id,
        params: {
          ...this.update,
          occurredAt,
          status: this.status as StatusType,
          body: this.body,
          affectedComponents: this.affectedComponents,
        },
      });
      this.isOpen = false;
      this.setToast({ message: 'The event has been update.' });
    } catch (e) {
      this.setToast({ message: 'Failed to save changes.', type: 'error' });
    }
  }

  protected closeDialog() {
    this.isOpen = !this.isOpen;
  }
}
