export const isRequired = (value: string) => !!value || 'This field is required';

const reEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,32})+$/;
export const isEmail = (value: string) => reEmail.test(value) || 'Invalid e-mail address';

const reAnalytics = /^UA-\d{4,9}-\d{1,4}$/i;
export const isAnalytics = (value: string) =>
  !value || reAnalytics.test(value) || 'Invalid google analytics tracking id';

// Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
export const isValidPassword = (value: string) =>
  (value.length >= 8 && /\d/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value)) ||
  'Invalid Password (min. 8 characters, as well as min. one upper- and lowercase letter and one number)';

// https://stackoverflow.com/a/26987741
const reDomain =
  // eslint-disable-next-line no-useless-escape
  /^(((?!-))(xn--|_{1,1})?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9\-]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/i;
export const isDomain = (value: string) => !value || reDomain.test(value) || 'This domain looks invalid';

export default {
  required: isRequired,
  email: isEmail,
  analytics: isAnalytics,
  password: isValidPassword,
  domain: isDomain,
};
