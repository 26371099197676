import * as config from '@/config';
import { ackeeHook } from '@/plugins/ackee';
import { confirmInvitation } from '@/router/guards/confirmInvitation.guard';
import { confirmUser } from '@/router/guards/confirmUser.guard';
import { isLoggedInGuard } from '@/router/guards/isLoggedIn.guard';
import { isLoggedOutGuard } from '@/router/guards/isLoggedOut.guard';
import Router from 'vue-router';
import store from '@/store';
import Vue from 'vue';

import Account from '@/views/Account.vue';
import Empty from '@/views/Empty.vue';
import Login from '@/views/Login.vue';
import PageComponents from '@/views/PageComponents.vue';
import PageCreate from '@/views/PageCreate.vue';
import PageEvent from '@/views/PageEvent.vue';
import PageEventCreate from '@/views/PageEventCreate.vue';
import PageEvents from '@/views/PageEvents.vue';
import PageMembers from '@/views/PageMembers.vue';
import Pages from '@/views/Pages.vue';
import PageSettings from '@/views/PageSettings.vue';
import PageSubscribers from '@/views/PageSubscribers.vue';
import RecoverPassword from '@/views/RecoverPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import Signup from '@/views/Signup.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: isLoggedOutGuard,
      meta: { withHeader: false },
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      beforeEnter: isLoggedOutGuard,
      meta: { withHeader: false },
    },
    {
      path: '/recover-password',
      name: 'recover-password',
      component: RecoverPassword,
      beforeEnter: isLoggedOutGuard,
      meta: { withHeader: false },
    },
    {
      path: '/reset-password/:resetId/:token',
      name: 'reset-password',
      component: ResetPassword,
      beforeEnter: isLoggedOutGuard,
      meta: { withHeader: false },
    },
    {
      path: '/confirm-user',
      name: 'confirm-user',
      component: Empty,
      beforeEnter: confirmUser,
    },
    {
      path: '/confirm-invitation',
      name: 'confirm-invitation',
      component: Empty,
      beforeEnter: confirmInvitation,
    },
    // redirect to website
    {
      path: '/terms',
      name: 'terms',
      beforeEnter: async (_to, _from, next) => {
        const { websiteUrl } = await config.load();
        next(new URL('/terms', websiteUrl).toString());
      },
    },
    // redirect to website
    {
      path: '/privacy',
      name: 'privacy',
      beforeEnter: async (_to, _from, next) => {
        const { websiteUrl } = await config.load();
        next(new URL('/privacy', websiteUrl).toString());
      },
    },
    {
      path: '/',
      component: Empty,
      beforeEnter: isLoggedInGuard,
      children: [
        {
          path: '',
          name: 'home',
          beforeEnter: async (_to, _from, next) => {
            await store.dispatch('listPages');
            const pages = store.getters.pages;
            if (pages.length > 0) {
              next({ name: 'page', params: { pageId: pages[0]._id } });
            } else {
              next({ name: 'page:create' });
            }
          },
        },
        {
          path: '/logout',
          name: 'logout',
          beforeEnter: async (_to, _from, next) => {
            await store.dispatch('logout');
            next({ name: 'login' });
          },
        },
        {
          path: '/account',
          name: 'account',
          component: Account,
        },
        {
          path: '/pages/create',
          name: 'page:create',
          component: PageCreate,
        },
        {
          path: '/pages/:pageId',
          name: 'page',
          component: Pages,
          redirect: { name: 'page/events' },
          children: [
            {
              path: 'events',
              name: 'page/events',
              component: PageEvents,
            },
            {
              path: 'events/create',
              name: 'page/events:create',
              component: PageEventCreate,
            },
            {
              path: 'events/:eventId',
              name: 'page/event',
              component: PageEvent,
            },
            {
              path: 'components',
              name: 'page/components',
              component: PageComponents,
            },
            {
              path: 'subscriptions',
              name: 'page/subscribers',
              component: PageSubscribers,
            },
            {
              path: 'members',
              name: 'page/members',
              component: PageMembers,
            },
            {
              path: 'settings',
              name: 'page/settings',
              component: PageSettings,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      redirect: { name: 'home' },
    },
  ],
});

router.afterEach(ackeeHook);

export default router;
