



























import { Component, Prop, Vue } from 'vue-property-decorator';
import { getStatusMetadata, StatusMetadata } from '@/constants';
import { Action } from 'vuex-class';
import { IUpdate } from '@/api';

@Component
export default class EventUpdate extends Vue {
  @Prop(Object)
  protected update!: IUpdate;

  @Action
  protected deleteUpdate!: (updateId: string) => Promise<void>;

  get metadata(): StatusMetadata {
    return getStatusMetadata(this.update.status);
  }
}
