
































import { Action, Getter, Mutation } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { IEvent, IPage, IUpdate, ObjectId } from '@/api';
import { IToast } from '@/store/modules/toast';

@Component<PageEvent>({
  metaInfo() {
    return {
      title: this.event?.title || 'Incident',
    };
  },
})
export default class PageEvent extends Vue {
  @Getter
  protected page!: IPage;

  @Getter
  protected event!: IEvent;

  @Getter
  protected updates!: IUpdate[];

  @Action
  protected getPage!: (pageId: ObjectId) => Promise<void>;

  @Action
  protected getEvent!: (eventId: ObjectId) => Promise<void>;

  @Action
  protected listUpdates!: (eventId: ObjectId) => Promise<void>;

  @Action
  protected deleteEvent!: (eventId: ObjectId) => Promise<void>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  @Watch('$route', { immediate: true })
  protected async onRouteChanged() {
    const { pageId, eventId } = this.$route.params;
    await this.getPage(pageId);
    await this.getEvent(eventId);
    // TODO previously this was sorted chronologically ascending,
    // but the API currently delivers it descending by occurredAt
    await this.listUpdates(eventId);
  }

  protected async onDeleteEvent() {
    await this.deleteEvent(this.event._id);
    this.$router.push({ name: 'page', params: { pageId: this.page._id } });
  }
}
