




























import { Action, Mutation } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISubscriber, ObjectId } from '@/api';
import { IToast } from '@/store/modules/toast';

@Component
export default class DialogSubscriberResendInvitation extends Vue {
  protected isOpen = false;

  @Prop()
  protected subscriber!: ISubscriber;

  @Action
  protected reinviteSubscription!: (subscriberId: ObjectId) => Promise<void>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  protected async onConfirm() {
    try {
      await this.reinviteSubscription(this.subscriber._id);
      this.isOpen = false;
      this.setToast({ message: 'The invitation e-mail has been resent successfully.' });
    } catch (e) {
      this.setToast({ message: 'Failed to resend invitation e-mail.', type: 'error' });
    }
  }

  protected closeDialog() {
    this.isOpen = !this.isOpen;
  }
}
