import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '@/store';

export interface ILoadingState {
  // The number of currently ongoing requests.
  loading: number;
}

const state: ILoadingState = {
  loading: 0,
};

const getters: GetterTree<ILoadingState, IRootState> = {
  isLoading: (state: ILoadingState): boolean => state.loading > 0,
};

const mutations: MutationTree<ILoadingState> = {
  startLoading(state: ILoadingState) {
    state.loading++;
  },
  finishedLoading(state: ILoadingState) {
    // timeout to prevent on/off “flickering”,
    // when a new request follows immediately
    setTimeout(() => {
      state.loading--;
    }, 50);
  },
};

const actions: ActionTree<ILoadingState, IRootState> = {};

const module: Module<ILoadingState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default module;
