
























import { Component, Vue } from 'vue-property-decorator';
import { IPage, ObjectId } from '@/api';
import { Getter } from 'vuex-class';
import { RawLocation } from 'vue-router';

const defaultRoute = 'page';
const translatableRoutes: string[] = [
  'page',
  'page/events:create',
  'page/event',
  'page/components',
  'page/subscribers',
  'page/members',
  'page/settings',
];

@Component
export default class PageSelect extends Vue {
  @Getter
  protected page!: IPage;

  @Getter
  protected pages!: IPage[];

  protected route(pageId: ObjectId): RawLocation {
    const current = this.$route.name;
    const name = current && translatableRoutes.includes(current) ? current : defaultRoute;
    return { name, params: { pageId } };
  }
}
