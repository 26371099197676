































import { Action, Mutation } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ICreateOrUpdateEventData } from '@/store/modules/event';
import { IEvent } from '@/api';
import { IToast } from '@/store/modules/toast';
import rules from '@/utils/validation';

@Component
export default class DialogEventEdit extends Vue {
  protected readonly rules = rules;
  protected isOpen = false;
  protected isFormValid = false;
  protected title = '';

  @Prop()
  protected event!: IEvent;

  @Action
  protected updateEvent!: (data: ICreateOrUpdateEventData) => Promise<void>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  @Watch('event', { immediate: true })
  protected onEventChanged() {
    this.title = this.event.title;
  }

  protected async onConfirm() {
    try {
      await this.updateEvent({
        eventId: this.event._id,
        params: {
          ...this.event,
          title: this.title,
        },
      });
      this.isOpen = false;
      this.setToast({ message: 'The event has been update.' });
    } catch (e) {
      this.setToast({ message: 'Failed to save changes.', type: 'error' });
    }
  }

  protected closeDialog() {
    this.isOpen = !this.isOpen;
  }
}
