

























































import { Action, Getter } from 'vuex-class';
import { IPage, ObjectId } from '@/api';
import { Component as VComponent, Vue, Watch } from 'vue-property-decorator';
import { ackeeAction } from '@/plugins/ackee';

@VComponent<PageSettings>({
  metaInfo: {
    title: 'Settings',
  },
})
export default class PageSettings extends Vue {
  @Getter
  protected page!: IPage;

  @Action
  protected getPage!: (pageId: ObjectId) => Promise<void>;

  @Action
  protected deletePage!: (id: ObjectId) => Promise<void>;

  @Watch('$route', { immediate: true })
  protected onPageChanged() {
    const { pageId } = this.$route.params;
    this.getPage(pageId);
  }

  // allow to jump directly to the tabs via e.g. `?tab=general`
  // https://medium.com/untitled-factory/linking-to-a-specific-tab-in-vuetify-js-d978525f2e1a
  protected get tab(): string {
    const tab = this.$route.query.tab;
    return typeof tab === 'string' ? tab : 'general';
  }

  protected set tab(tab: string) {
    this.$router.replace({ query: { ...this.$route.query, tab } });
    ackeeAction('click', { key: `settings-${tab}`, value: 1 });
  }
}
