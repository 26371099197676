




















































import { Action, Getter, Mutation } from 'vuex-class';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { EVENT_STATUS_RESOLVED, getStatusMetadata, INCIDENT_STATUS } from '@/constants';
import {
  IComponent,
  ICreateOrUpdateEventAffectedComponent,
  ICreateOrUpdateUpdateParams,
  IEvent,
  IPage,
  IUpdate,
  ObjectId,
  StatusType,
} from '@/api';
import capitalize from 'lodash/capitalize';
import { IToast } from '@/store/modules/toast';
import rules from '@/utils/validation';
import { VuetifyForm } from '@/plugins/vuetify';

@Component
export default class EventUpdateCreate extends Vue {
  protected rules = rules;
  protected isFormValid = false;
  protected status = 0;
  protected body = '';
  protected affectedComponents: ICreateOrUpdateEventAffectedComponent[] = [];

  @Ref()
  protected readonly form!: VuetifyForm;

  @Prop()
  protected page!: IPage;

  @Prop()
  protected event!: IEvent;

  @Getter
  protected components!: IComponent[];

  @Getter
  protected updates!: IUpdate[];

  @Action
  protected listComponents!: (pageId: ObjectId) => Promise<void>;

  @Action
  protected listUpdates!: (eventId: ObjectId) => Promise<void>;

  @Action
  protected createUpdate!: (params: ICreateOrUpdateUpdateParams) => Promise<IUpdate>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  @Watch('$route', { immediate: true })
  protected async onRouteChanged() {
    await this.listComponents(this.page._id);
    await this.listUpdates(this.event._id);
  }

  @Watch('updates', { immediate: true })
  protected onUpdatesChanged() {
    this.status = Math.max(0, INCIDENT_STATUS.indexOf(this.updates[0]?.status || ''));
    this.body = '';
    this.affectedComponents = this.updates[0]?.affectedComponents || [];
    this.form?.resetValidation();
  }

  @Watch('status', { immediate: true })
  protected async onStatusChanged() {
    await this.$nextTick();
    this.form?.validate();
  }

  protected get isStatusResolved() {
    return INCIDENT_STATUS[this.status] === EVENT_STATUS_RESOLVED;
  }

  protected get statusLabels(): string[] {
    return INCIDENT_STATUS.map((i) => capitalize(i));
  }

  protected get placeholder() {
    return getStatusMetadata(INCIDENT_STATUS[this.status] as StatusType).placeholder;
  }

  protected async onCreateUpdate() {
    try {
      await this.createUpdate({
        page: this.page._id,
        event: this.event._id,
        body: this.body,
        status: INCIDENT_STATUS[this.status] as StatusType,
        affectedComponents: this.affectedComponents,
        sendNotification: true,
      });
      this.setToast({ message: 'The update has been successfully published.' });
    } catch (e) {
      this.setToast({ message: 'Failed to save incident update.', type: 'error' });
    }
  }
}
