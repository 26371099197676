






import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { IPage } from '@/api';
import { MetaInfo } from 'vue-meta';

@Component<Pages>({
  metaInfo(): MetaInfo {
    return this.page ? { titleTemplate: `${this.page.name}: %s | PageFate` } : {};
  },
})
export default class Pages extends Vue {
  @Getter
  protected page!: IPage;

  // TODO consider to only load the page once here, and not each time in the sub components?
}
