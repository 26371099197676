



















































import { Action, Mutation, namespace } from 'vuex-class';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { ackeeAction } from '@/plugins/ackee';
import { IRegisterUserParams } from '@/api';
import { IToast } from '@/store/modules/toast';
import rules from '@/utils/validation';
import { VuetifyForm } from '@/plugins/vuetify';

const initialData = (): IRegisterUserParams => ({ email: '', password: '' });

// necessary to avoid Vue warning/error messages about route redirect
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOp = () => {};

@Component<Signup>({
  metaInfo: {
    title: 'Signup',
  },
})
export default class Signup extends Vue {
  protected readonly rules = rules;

  @Ref() protected readonly form!: VuetifyForm;

  protected credentials: IRegisterUserParams = initialData();

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  @namespace('loading').Getter
  protected isLoading!: boolean;

  @Action
  protected signup!: (data: IRegisterUserParams) => Promise<void>;

  protected next: string | null = null;

  protected emailReadOnly = false;

  protected mounted() {
    const { next, email } = this.$route.query;
    if (typeof next === 'string') {
      this.next = next;
    }
    if (typeof email === 'string') {
      this.credentials.email = email;
      this.emailReadOnly = true;
    }
  }

  protected async doSignup() {
    if (!this.form.validate()) {
      return;
    }

    try {
      ackeeAction('click', { key: 'signup', value: 1 });
      await this.signup(this.credentials);
    } catch (e) {
      this.setToast({ message: 'Signup failed. Please correct the marked fields.', type: 'error' });
      return;
    }

    this.setToast({ message: "Welcome to PageFate! We've sent you a confirmation e-mail" });

    if (this.next) {
      this.$router.push(this.next).catch(noOp);
    } else {
      this.$router.push({ name: 'home' }).catch(noOp);
    }
  }
}
