


































import * as config from '@/config';
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { IPage, IUserWithAbilities } from '@/api';
import { IToast } from '@/store/modules/toast';

declare const Paddle: any;

@Component
export default class Subscription extends Vue {
  @Getter
  protected page!: IPage;

  @Getter
  protected me!: IUserWithAbilities;

  @Getter
  protected darkMode!: boolean;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  protected async openPaddleSubscriptionDialog() {
    const { paddleEnvironment, paddleVendorId, paddleProductId } = await config.load();
    if (typeof paddleProductId !== 'string' || typeof paddleVendorId !== 'string') {
      throw new Error('Paddle configuration is missing');
    }
    // https://developer.paddle.com/guides/how-tos/checkout/paddle-checkout
    if (paddleEnvironment) {
      Paddle.Environment.set(paddleEnvironment);
    }
    Paddle.Setup({ vendor: parseInt(paddleVendorId) });
    Paddle.Checkout.open({
      product: parseInt(paddleProductId),
      email: this.me.email,
      // TODO pass further parameters here? e.g. which user upgraded, …
      passthrough: JSON.stringify({ pageId: this.page._id }),
      successCallback: () => {
        // if we should need the order details:
        // https://developer.paddle.com/guides/how-tos/checkout/post-checkout#order-details
        this.page.activeSubscription = true;
        // set next billing date to now plus one month, after refresh we get the proper date
        const now = new Date();
        const billingNext = new Date(now.setMonth(now.getMonth() + 1));
        this.page.paddleNextBillingDate = billingNext.toISOString();
      },
    });
  }
}
