











import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { IToast } from '@/store/modules/toast';

@Component
export default class Toast extends Vue {
  @Getter
  protected toast!: IToast;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  get color(): string | void {
    switch (this.toast?.type) {
      case 'error':
        return 'error';
      case 'info':
      default:
        return 'success';
    }
  }

  onDismissed(): void {
    this.setToast(null);
  }
}
