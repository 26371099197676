import * as api from '@/api';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { get, set } from '@/utils/vuex';
import { ICreateSubscriberParams, ISubscriber } from '@/api';
import { IRootState } from '@/store';

export interface ISubscribersState {
  subscriber: ISubscriber | null;
  subscribers: ISubscriber[];
}

const state: ISubscribersState = {
  subscriber: null,
  subscribers: [],
};

const getters: GetterTree<ISubscribersState, IRootState> = {
  subscriber: get('subscriber'),
  subscribers: get('subscribers'),
};

const mutations: MutationTree<ISubscribersState> = {
  setSubscriber: set('subscriber'),
  setSubscribers: set('subscribers'),
};

const actions: ActionTree<ISubscribersState, IRootState> = {
  async listSubscribers(context: ActionContext<ISubscribersState, IRootState>, pageId?: string) {
    const pageIdentifier = pageId || context.rootState.pages.page?._id;
    if (!pageIdentifier) throw new Error('No page provided or in context');
    const subscribers = await api.listSubscribers(pageIdentifier);
    context.commit('setSubscribers', subscribers);
    return subscribers;
  },
  async getSubscriber(context: ActionContext<ISubscribersState, IRootState>, subscriberId: string) {
    const subscriber = await api.getSubscriber(subscriberId);
    context.commit('setSubscriber', subscriber);
    return subscriber;
  },
  async createSubscriber(context: ActionContext<ISubscribersState, IRootState>, data: ICreateSubscriberParams) {
    const subscriber = await api.createSubscriber(data);
    context.commit('setSubscriber', subscriber);
    await context.dispatch('listSubscribers');
    return subscriber;
  },
  async reinviteSubscription(context: ActionContext<ISubscribersState, IRootState>, subscriberId: string) {
    const subscriber = await api.reinviteSubscription({ subscriber: subscriberId });
    context.commit('setSubscriber', subscriber);
    await context.dispatch('listSubscribers');
    return subscriber;
  },
  async deleteSubscriber(context: ActionContext<ISubscribersState, IRootState>, subscriberId: string) {
    await api.deleteSubscriber(subscriberId);
    context.commit('setSubscriber', null);
    await context.dispatch('listSubscribers');
  },
};

const module: Module<ISubscribersState, IRootState> = {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
};

export default module;
