










































import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getImpactMetadata, ImpactMetadata } from '@/constants';
import { IComponent, ObjectId } from '@/api';

@Component
export default class PageComponent extends Vue {
  @Prop(Object)
  protected component!: IComponent;

  @Getter
  protected darkMode!: boolean;

  @Getter
  protected components!: IComponent[];

  @Action
  protected deleteComponent!: (id: ObjectId) => void;

  get children(): IComponent[] {
    return this.components.filter((c) => c.parent === this.component._id);
  }

  get metadata(): ImpactMetadata {
    return getImpactMetadata(this.component.impact);
  }
}
