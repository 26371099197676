








































































import { Action, Getter, Mutation } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ICreateOrUpdatePageParams, IPage } from '@/api';
import { ackeeAction } from '@/plugins/ackee';
import { ICreateOrUpdatePageData } from '@/store/modules/page';
import { IToast } from '@/store/modules/toast';
import rules from '@/utils/validation';

@Component
export default class Design extends Vue {
  protected rules = rules;

  protected isFormValid = true;

  protected data: ICreateOrUpdatePageParams = {
    name: '',
    slug: '',
    colorHeader: '',
  };

  @Getter
  protected page!: IPage;

  @Action
  protected updatePage!: (data: ICreateOrUpdatePageData) => Promise<void>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  @Watch('page', { immediate: true })
  protected onPageChanged() {
    if (this.page) {
      this.data = { ...this.page };
    }
  }

  protected async onConfirmUpdate() {
    try {
      await this.updatePage({ pageId: this.page._id, params: this.data });
    } catch (e) {
      this.setToast({ message: 'Failed to save changes.', type: 'error' });
      return;
    }

    this.setToast({ message: 'The page has been successfully updated.' });
  }

  protected get impactImage(): string | undefined {
    if (!this.page) return undefined;
    return new URL('/impact.svg', this.page.url).toString();
  }

  protected get embeddingCode(): string | undefined {
    if (!this.page || !this.impactImage) return undefined;
    return `<a href="${this.page.url}" target="_blank"><img src="${this.impactImage}" style="width: 10px; height: 10px; margin-right: 5px">Status</a>`;
  }

  protected async copyEmbeddingHtml(): Promise<void> {
    if (!this.embeddingCode) return;
    await navigator.clipboard.writeText(this.embeddingCode);
    ackeeAction('click', { key: 'copy-embedding-html', value: 1 });
    this.setToast({ message: 'Copied HTML to clipboard.' });
  }
}
