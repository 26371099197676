import * as api from '@/api';
import { NavigationGuard } from 'vue-router';
import store from '@/store';

export const confirmInvitation: NavigationGuard = async (to, _from, next) => {
  const { token } = to.query;
  if (typeof token !== 'string') {
    store.commit('setToast', { message: 'No confirmation token was given.' });
    next('home');
    return;
  }
  const checkResult = await api.checkMemberToken(token);
  try {
    await store.dispatch('getMe');
    const me = store.state.auth.me;
    if (checkResult.email === me?.email) {
      // (1) logged in && same user -> confirm!
      const member = await api.confirmMember({ token });
      store.commit('setToast', { message: 'Welcome! Your membership was confirmed.' });
      next({ name: 'page', params: { pageId: member.page } });
    } else {
      // (2) logged in && different user -> error
      store.commit('setToast', {
        message: `This invitation must be accepted with ${checkResult.email}`,
        type: 'error',
      });
      next('home');
    }
  } catch (err) {
    if (checkResult.userExists) {
      // (3) not logged in && user exists -> go to log in
      next({ name: 'login', query: { next: to.fullPath, email: checkResult.email } });
    } else {
      // (4) not logged in && user does not exist -> go to sign up
      next({ name: 'signup', query: { next: to.fullPath, email: checkResult.email } });
    }
  }
};
