




import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime as LuxonDateTime } from 'luxon';

@Component
export default class DateTime extends Vue {
  @Prop({ type: String, required: true })
  protected value!: string;

  @Prop({ type: Object, default: () => LuxonDateTime.DATETIME_MED })
  protected format!: object;

  protected get dateFormat(): string {
    return LuxonDateTime.fromISO(this.value).toLocaleString(this.format);
  }
}
