



















































import { Action, Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { IMember, IPage, ObjectId } from '@/api';
import { DataTableHeader } from 'vuetify';

interface IOwnerOrMember extends IMember {
  isOwner?: boolean;
}

@Component<PageMembers>({
  metaInfo: {
    title: 'Members',
  },
})
export default class PageMembers extends Vue {
  protected search = '';
  protected headers: DataTableHeader[] = [
    { text: 'Confirmed', value: 'acceptedAt', align: 'center', sortable: true },
    { text: 'E-Mail', value: 'email', align: 'start', sortable: true },
    { text: 'Date', value: 'createdAt', align: 'center', sortable: true },
    { text: 'Actions', value: 'actions', align: 'center', sortable: false },
  ];

  @Getter
  protected page!: IPage;

  @Getter
  protected members!: IMember[];

  @Action
  protected getPage!: (pageId: ObjectId) => void;

  @Action
  protected listMembers!: (pageId: ObjectId) => void;

  @Action
  protected deleteMember!: (memberId: ObjectId) => void;

  @Watch('$route', { immediate: true })
  protected onPageChanges() {
    const { pageId } = this.$route.params;
    this.getPage(pageId);
    this.listMembers(pageId);
  }

  get ownerAndMembers(): IOwnerOrMember[] {
    const members: IOwnerOrMember[] = [...this.members];
    const owner = this.page?.owner;
    if (owner) {
      members.unshift({
        _id: owner._id,
        kind: 'member',
        email: owner.email,
        page: this.page._id,
        acceptedAt: this.page.createdAt,
        createdAt: owner.createdAt,
        updatedAt: owner.updatedAt,
        isOwner: true,
      });
    }
    return members;
  }
}
