import * as api from '@/api';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { get, set } from '@/utils/vuex';
import { IComponent, ICreateOrUpdateComponentParams } from '@/api';
import { IRootState } from '@/store';

export interface ICreateOrUpdateComponentData {
  componentId: string;
  params: ICreateOrUpdateComponentParams;
}

export interface IComponentsState {
  component: IComponent | null;
  components: IComponent[];
}

const state: IComponentsState = {
  component: null,
  components: [],
};

const getters: GetterTree<IComponentsState, IRootState> = {
  component: get('component'),
  components: get('components'),
};

const mutations: MutationTree<IComponentsState> = {
  setComponent: set('component'),
  setComponents: set('components'),
};

const actions: ActionTree<IComponentsState, IRootState> = {
  async listComponents(context: ActionContext<IComponentsState, IRootState>, pageId?: string) {
    const pageIdentifier = pageId || context.rootState.pages.page?._id;
    if (!pageIdentifier) throw new Error('No page provided or in context');
    const components = await api.listComponents(pageIdentifier);
    context.commit('setComponents', components);
    return components;
  },
  async getComponent(context: ActionContext<IComponentsState, IRootState>, componentId: string) {
    const component = await api.getComponent(componentId);
    context.commit('setComponent', component);
    return component;
  },
  async createComponent(context: ActionContext<IComponentsState, IRootState>, data: ICreateOrUpdateComponentParams) {
    const component = await api.createComponent(data);
    context.commit('setComponent', component);
    await context.dispatch('listComponents');
    return component;
  },
  async updateComponent(context: ActionContext<IComponentsState, IRootState>, data: ICreateOrUpdateComponentData) {
    const component = await api.updateComponent(data.componentId, data.params);
    context.commit('setComponent', component);
    await context.dispatch('listComponents');
    return component;
  },
  async deleteComponent(context: ActionContext<IComponentsState, IRootState>, componentId: string) {
    await api.deleteComponent(componentId);
    context.commit('setComponent', null);
    await context.dispatch('listComponents');
  },
};

const module: Module<IComponentsState, IRootState> = {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
};

export default module;
