import * as api from '@/api';
import { NavigationGuard } from 'vue-router';
import store from '@/store';

export const confirmUser: NavigationGuard = async (to, _from, next) => {
  const { token } = to.query;
  if (typeof token !== 'string') {
    store.commit('setToast', { message: 'No confirmation token was given.' });
    next('home');
    return;
  }
  try {
    await api.confirmUser({ token });
    store.commit('setToast', { message: 'Welcome! Your account was confirmed.' });
  } catch (err: any) {
    store.commit('setToast', {
      message: `Couldn’t confirm account: ${err.data.message ?? 'Unknown error'}`,
      type: 'error',
    });
  }
  next('home');
};
