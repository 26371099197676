

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Language } from '@/api';

@Component
export default class LanguageSelect extends Vue {
  @Prop()
  protected value?: Language;

  protected onLanguageChanged(language: Language) {
    this.$emit('input', language);
  }
}
