























import { Component, Prop, Vue } from 'vue-property-decorator';
import capitalize from 'lodash/capitalize';
import { IToast } from '@/store/modules/toast';
import { Mutation } from 'vuex-class';
import { ObjectId } from '@/api';
import { RawLocation } from 'vue-router';

@Component
export default class DialogDelete extends Vue {
  protected isOpen = false;

  @Prop()
  protected value?: { _id: ObjectId; kind: string };

  @Prop()
  protected callback!: (_id: ObjectId) => void | Promise<void>;

  @Prop({ default: null })
  protected then!: RawLocation | null;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  protected get entityName() {
    return capitalize(this.value?.kind);
  }

  protected get title() {
    return `Delete ${this.entityName}`;
  }

  protected async onConfirmDelete() {
    if (!this.value) return;

    const { _id, kind } = this.value;

    try {
      await this.callback(_id);
    } catch (e) {
      this.setToast({ message: `Failed to delete this ${kind}.`, type: 'error' });
      return;
    }

    this.isOpen = false;
    this.setToast({ message: `The ${kind} has been deleted successfully.` });

    if (this.then !== null) {
      this.$router.push(this.then);
    }
  }

  protected closeDialog() {
    this.isOpen = !this.isOpen;
  }
}
