import auth, { IAuthState } from '@/store/modules/auth';
import components, { IComponentsState } from '@/store/modules/component';
import events, { IEventsState } from '@/store/modules/event';
import loading, { ILoadingState } from '@/store/modules/loading';
import members, { IMemberState } from '@/store/modules/member';
import pages, { IPagesState } from '@/store/modules/page';
import settings, { ISettingsState } from '@/store/modules/settings';
import subscribers, { ISubscribersState } from '@/store/modules/subscriber';
import toast, { IToastState } from '@/store/modules/toast';
import updates, { IUpdateState } from '@/store/modules/update';
import Vuex, { createLogger, ModuleTree, Plugin } from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

export interface IRootState {
  auth: IAuthState;
  components: IComponentsState;
  events: IEventsState;
  loading: ILoadingState;
  members: IMemberState;
  pages: IPagesState;
  settings: ISettingsState;
  toast: IToastState;
  subscribers: ISubscribersState;
  updates: IUpdateState;
}

const modules: ModuleTree<IRootState> = {
  auth,
  components,
  events,
  loading,
  members,
  pages,
  settings,
  subscribers,
  toast,
  updates,
};

const plugins: Plugin<IRootState>[] = [];
if (process.env.NODE_ENV === 'development') {
  plugins.push(createLogger<IRootState>());
}

export default new Vuex.Store<IRootState>({
  modules,
  plugins,
});
