



































import { Action, Getter, Mutation } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IComponent, ICreateOrUpdateComponentParams, IPage } from '@/api';
import { IToast } from '@/store/modules/toast';
import rules from '@/utils/validation';

const initialData = (): ICreateOrUpdateComponentParams => ({
  name: '',
  description: '',
  page: '',
});

@Component
export default class DialogComponentCreate extends Vue {
  protected readonly rules = rules;

  @Prop({
    type: Object,
    default: () => null,
    required: false,
    validator: (value) => value === null || value.parent === null,
  })
  protected parent?: IComponent;

  protected isOpen = false;

  protected isFormValid = false;
  protected data: ICreateOrUpdateComponentParams = initialData();

  @Getter
  protected page!: IPage;

  @Getter
  protected components!: IComponent[];

  @Action
  protected createComponent!: (data: ICreateOrUpdateComponentParams) => void;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  protected mounted() {
    this.data = initialData();
  }

  protected get rootComponents(): IComponent[] {
    return this.components.filter((c) => !c.parent);
  }

  protected async onConfirmCreate() {
    const data = { ...this.data, page: this.page._id };
    try {
      await this.createComponent(data);
    } catch (e) {
      this.setToast({ message: 'Failed to create component.', type: 'error' });
      return;
    }

    this.isOpen = false;
    this.setToast({ message: 'The component has been successfully created.' });
    this.data = initialData();
  }

  protected closeDialog() {
    this.isOpen = !this.isOpen;
  }
}
