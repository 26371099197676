




































import { Action, Getter, Mutation } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { IEvent, IPage, ObjectId } from '@/api';
import { IToast } from '@/store/modules/toast';

@Component<PageEvents>({
  metaInfo: {
    title: 'Incidents',
  },
})
export default class PageEvents extends Vue {
  @Getter
  protected page!: IPage;

  @Getter
  protected resolvedEvents!: IEvent[];

  @Getter
  protected unresolvedEvents!: IEvent[];

  @Action
  protected getPage!: (pageId: ObjectId) => Promise<void>;

  @Action
  protected listEvents!: (pageId: ObjectId) => Promise<void>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  @Watch('$route', { immediate: true })
  protected async onPageChanges() {
    const { pageId } = this.$route.params;
    await this.getPage(pageId);
    await this.listEvents(pageId);
  }
}
