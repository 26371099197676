

















































import * as api from '@/api';
import * as config from '@/config';
import { Action, Getter, Mutation } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ICreateOrUpdatePageParams, IPage } from '@/api';
import { debounce } from 'lodash';
import { ICreateOrUpdatePageData } from '@/store/modules/page';
import { IToast } from '@/store/modules/toast';
import rules from '@/utils/validation';

@Component
export default class Domain extends Vue {
  protected rules = rules;

  protected isFormValid = true;

  protected data: ICreateOrUpdatePageParams = {
    name: '',
    slug: '',
  };

  protected protocol = '';

  protected host = '';

  /** Async validation errors. */
  protected slugErrors: string[] = [];

  @Getter
  protected page!: IPage;

  @Action
  protected updatePage!: (data: ICreateOrUpdatePageData) => Promise<void>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  @Watch('page', { immediate: true })
  protected onPageChanged() {
    if (this.page) {
      this.data = { ...this.page };
      // XXX Force a slug validation on load for now as we have a couple of
      // pages with invalid slugs and we want to make users aware of it and
      // force them to change it on their behalf.
      this.checkSlug();
    }
  }

  protected async mounted() {
    const { websiteUrl } = await config.load();
    const { host, protocol } = new URL(websiteUrl);
    this.host = host;
    this.protocol = protocol;
  }

  protected async onConfirmUpdate() {
    try {
      await this.updatePage({ pageId: this.page._id, params: this.data });
    } catch (e) {
      this.setToast({ message: 'Failed to save changes.', type: 'error' });
      return;
    }

    this.setToast({ message: 'The page has been successfully updated.' });
  }

  @Watch('data.slug')
  protected checkSlug = debounce(() => this._checkSlug(), 100);

  /** nb: Keep separate from `checkSlug` due to `this` reference. */
  private async _checkSlug() {
    const { result } = await api.checkSlug(this.data.slug);
    if (result === 'taken' && this.data.slug !== this.page.slug) {
      this.slugErrors = ['Slug is already taken'];
    } else if (result === 'invalid') {
      this.slugErrors = ['Slug must consist of at least four lowercase, number or hyphen characters'];
    } else {
      this.slugErrors = [];
    }
  }
}
