import * as api from '@/api';
import { ActionContext, ActionTree, GetterTree, Module } from 'vuex';
import { get, set } from '@/utils/vuex';
import { ICreateOrUpdateEventParams, IEvent } from '@/api';
import { IRootState } from '@/store';

export interface ICreateOrUpdateEventData {
  eventId: string;
  params: ICreateOrUpdateEventParams;
}

export interface IEventsState {
  event: IEvent | null;
  events: IEvent[];
}

const state: IEventsState = {
  event: null,
  events: [],
};

const getters: GetterTree<IEventsState, IRootState> = {
  event: get('event'),
  events: get('events'),
  resolvedEvents: ({ events }: IEventsState) => events.filter((e) => e.resolvedAt),
  unresolvedEvents: ({ events }: IEventsState) => events.filter((e) => !e.resolvedAt),
};

const mutations = {
  setEvent: set('event'),
  setEvents: set('events'),
};

const actions: ActionTree<IEventsState, IRootState> = {
  async listEvents(context: ActionContext<IEventsState, IRootState>, pageId?: string) {
    const pageIdentifier = pageId || context.rootState.pages.page?._id;
    if (!pageIdentifier) throw new Error('No page provided or in context');
    const events = await api.listEvents(pageIdentifier);
    context.commit('setEvents', events);
    return events;
  },
  async getEvent(context: ActionContext<IEventsState, IRootState>, eventId: string) {
    const event = await api.getEvent(eventId);
    context.commit('setEvent', event);
    return event;
  },
  async createEvent(context: ActionContext<IEventsState, IRootState>, data: ICreateOrUpdateEventParams) {
    const event = await api.createEvent(data);
    context.commit('setEvent', event);
    await context.dispatch('listEvents');
    return event;
  },

  async updateEvent(context: ActionContext<IEventsState, IRootState>, data: ICreateOrUpdateEventData) {
    const event = await api.updateEvent(data.eventId, data.params);
    context.commit('setEvent', event);
    await context.dispatch('listEvents');
    return event;
  },

  async deleteEvent(context: ActionContext<IEventsState, IRootState>, eventId: string) {
    await api.deleteEvent(eventId);
    context.commit('setEvent', null);
    await context.dispatch('listEvents');
  },
};

const module: Module<IEventsState, IRootState> = {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
};

export default module;
