

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IComponent, ICreateOrUpdateEventAffectedComponent, ImpactType } from '@/api';
import { IMPACT, IMPACT_OPERATIONAL } from '@/constants';
import { Getter } from 'vuex-class';

@Component
export default class AffectedComponent extends Vue {
  protected readonly IMPACT = IMPACT;

  @Prop({ type: Array, required: true })
  protected value!: ICreateOrUpdateEventAffectedComponent[];

  protected affectedComponents!: ICreateOrUpdateEventAffectedComponent[];

  @Prop(Object)
  protected component!: IComponent;

  @Prop()
  protected isStatusResolved!: boolean;

  @Getter
  protected darkMode!: boolean;

  @Getter
  protected components!: IComponent[];

  @Watch('value', { immediate: true })
  protected onValueChanged() {
    this.affectedComponents = [...this.value];
  }

  get rules() {
    return [(value: string) => !this.isStatusResolved || value === IMPACT_OPERATIONAL];
  }

  get impact(): ImpactType {
    const affectedComponent = this.value.find(({ component }) => component === this.component._id);
    if (affectedComponent) {
      return affectedComponent.impact;
    }
    return IMPACT_OPERATIONAL;
  }

  get children(): IComponent[] {
    return this.components.filter((c) => c.parent === this.component._id);
  }

  protected onImpactChange(impact: ImpactType) {
    const value: ICreateOrUpdateEventAffectedComponent[] = [
      ...this.value.filter(({ component }) => component !== this.component._id),
      {
        impact,
        component: this.component._id,
      },
    ];
    this.onAffectedComponentsChange(value);
  }

  protected onAffectedComponentsChange(value: ICreateOrUpdateEventAffectedComponent[]) {
    this.$emit('input', value);
  }
}
