




import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import humanizeDuration from 'humanize-duration';

@Component
export default class Since extends Vue {
  @Prop({ type: String, required: true })
  protected value!: string;

  @Prop({ type: Number, required: false, default: 1000 })
  protected updateInterval!: number;

  protected since = '';
  protected interval?: number;

  protected created() {
    this.update();
    this.interval = window.setInterval(this.update, this.updateInterval);
  }

  protected destroyed() {
    clearInterval(this.interval);
  }

  protected update() {
    const diff = DateTime.local().diff(DateTime.fromISO(this.value), 'milliseconds').milliseconds;
    this.since = humanizeDuration(diff, { round: true, units: ['d', 'h', 'm'] });
  }
}
