



































import { Action, Getter, Mutation } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ICreateOrUpdatePageParams, IPage } from '@/api';
import { ICreateOrUpdatePageData } from '@/store/modules/page';
import { IToast } from '@/store/modules/toast';
import LanguageSelect from '@/components/LanguageSelect.vue';
import rules from '@/utils/validation';

@Component({
  components: { LanguageSelect },
})
export default class General extends Vue {
  protected rules = rules;

  protected isFormValid = true;

  protected data: ICreateOrUpdatePageParams = {
    name: '',
    slug: '',
  };

  @Getter
  protected page!: IPage;

  @Action
  protected updatePage!: (data: ICreateOrUpdatePageData) => Promise<void>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  @Watch('page', { immediate: true })
  protected onPageChanged() {
    if (this.page) {
      this.data = { ...this.page };
    }
  }

  protected async onConfirm() {
    try {
      await this.updatePage({ pageId: this.page._id, params: this.data });
      this.setToast({ message: 'The page has been successfully updated.' });
    } catch (e) {
      this.setToast({ message: 'Failed to save changes.', type: 'error' });
      return;
    }
  }
}
