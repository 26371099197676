import { COOKIE_KEY_TOKEN } from '@/constants';
import Cookies from 'js-cookie';
import { NavigationGuard } from 'vue-router';
import store from '@/store';

export const isLoggedOutGuard: NavigationGuard = async (_to, _from, next) => {
  if (store.state.auth.me) {
    next('home');
    return;
  }

  const token = Cookies.get(COOKIE_KEY_TOKEN);
  if (!token) {
    next();
    return;
  }

  try {
    await store.dispatch('getMe');
    next('home');
  } catch (err: any) {
    next();
  }
};
