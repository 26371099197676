




import { Component, Prop, Vue } from 'vue-property-decorator';
import { getStatusMetadata, StatusMetadata } from '@/constants';
import { StatusType } from '@/api';

@Component
export default class EventStatus extends Vue {
  @Prop({ type: String })
  protected value!: StatusType;

  get metadata(): StatusMetadata {
    return getStatusMetadata(this.value as StatusType);
  }
}
