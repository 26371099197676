


































import { Action, Mutation } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IMember, IPage, ObjectId } from '@/api';
import { IToast } from '@/store/modules/toast';

@Component
export default class MemberResendInvitation extends Vue {
  protected isOpen = false;

  @Prop()
  protected page!: IPage;

  @Prop()
  protected member!: IMember;

  @Action
  protected reinviteMember!: (memberId: ObjectId) => Promise<void>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  protected async onConfirm() {
    try {
      await this.reinviteMember(this.member._id);
      this.isOpen = false;
      this.setToast({ message: 'The invitation has been resend successfully.' });
    } catch (e) {
      this.setToast({ message: 'Failed to resend the invitation.', type: 'error' });
    }
  }

  protected closeDialog() {
    this.isOpen = !this.isOpen;
  }
}
