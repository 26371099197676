
















































import { Action, Mutation, namespace } from 'vuex-class';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { ackeeAction } from '@/plugins/ackee';
import { ILoginParams } from '@/api';
import { IToast } from '@/store/modules/toast';
import rules from '@/utils/validation';
import { VuetifyForm } from '@/plugins/vuetify';

const initialData = (): ILoginParams => ({ email: '', password: '' });

// necessary to avoid Vue warning/error messages about route redirect
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOp = () => {};

@Component<Login>({
  metaInfo: {
    title: 'Login',
  },
})
export default class Login extends Vue {
  protected readonly rules = rules;

  @Ref() protected readonly form!: VuetifyForm;

  protected credentials: ILoginParams = initialData();

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  @namespace('loading').Getter
  protected isLoading!: boolean;

  @Action
  protected login!: (data: ILoginParams) => Promise<void>;

  protected next: string | null = null;

  protected emailReadOnly = false;

  protected mounted() {
    const { next, email } = this.$route.query;
    if (typeof next === 'string') {
      this.next = next;
    }
    if (typeof email === 'string') {
      this.credentials.email = email;
      this.emailReadOnly = true;
    }
  }

  protected async doLogin() {
    if (this.form.validate()) {
      try {
        ackeeAction('click', { key: 'login', value: 1 });
        await this.login(this.credentials);
      } catch (e) {
        this.setToast({
          message: 'Login failed. Please check your credentials and try again.',
          type: 'error',
        });
        return;
      }

      if (this.next) {
        this.$router.push(this.next).catch(noOp);
      } else {
        this.$router.push({ name: 'home' }).catch(noOp);
      }
    }
  }
}
