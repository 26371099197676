import * as api from '@/api';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { get, set } from '@/utils/vuex';
import { ICreateOrUpdatePageParams, IPage } from '@/api';
import { IRootState } from '@/store';

export interface ICreateOrUpdatePageData {
  pageId: string;
  params: ICreateOrUpdatePageParams;
}
export interface IPagesState {
  page: IPage | null;
  pages: IPage[];
}

const state: IPagesState = {
  page: null,
  pages: [],
};

const getters: GetterTree<IPagesState, IRootState> = {
  page: get('page'),
  pages: get('pages'),
};

const mutations: MutationTree<IPagesState> = {
  setPage: set('page'),
  setPages: set('pages'),
};

const actions: ActionTree<IPagesState, IRootState> = {
  async listPages(context: ActionContext<IPagesState, IRootState>) {
    const pages = await api.listPages();
    context.commit('setPages', pages);
    return pages;
  },
  async getPage(context: ActionContext<IPagesState, IRootState>, pageId: string) {
    const page = await api.getPage(pageId);
    context.commit('setPage', page);
    return page;
  },
  async createPage(context: ActionContext<IPagesState, IRootState>, data: ICreateOrUpdatePageParams) {
    const page = await api.createPage(data);
    context.commit('setPage', page);
    await context.dispatch('listPages');
    await context.dispatch('getMe');
    return page;
  },
  async updatePage(context: ActionContext<IPagesState, IRootState>, data: ICreateOrUpdatePageData) {
    const page = await api.updatePage(data.pageId, data.params);
    context.commit('setPage', page);
    await context.dispatch('listPages');
    return page;
  },
  async deletePage(context: ActionContext<IPagesState, IRootState>, pageId: string) {
    await api.deletePage(pageId);
    context.commit('setPage', null);
    await context.dispatch('listPages');
  },
};

const module: Module<IPagesState, IRootState> = {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
};

export default module;
