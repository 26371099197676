import { ImpactType, StatusType } from './api';
import { CookieAttributes } from 'js-cookie';

export const COOKIE_KEY_TOKEN = 'pagefate_t';
export const COOKIE_ATTRIBUTES: CookieAttributes = { path: '/', sameSite: 'Strict' };

interface Metadata {
  label: string;
  color: string;
  placeholder?: string;
}

export type ImpactMetadata = Metadata;
export type StatusMetadata = Metadata;

export function getImpactMetadata(impact: ImpactType): ImpactMetadata {
  switch (impact) {
    case 'operational':
      return { label: 'Operational', color: '#48B975' };
    case 'maintenance':
      return { label: 'Under Maintenance', color: '#2C7CB0' };
    case 'degraded':
      return { label: 'Degraded Performance', color: '#F38020' };
    case 'partial':
      return { label: 'Partial Outage', color: '#F38020' };
    case 'outage':
      return { label: 'Major Impaired', color: '#F38020' };
    default:
      return { label: 'Unknown', color: '#F38020' };
  }
}

export function getStatusMetadata(status: StatusType): StatusMetadata {
  switch (status) {
    case 'investigating':
      return {
        label: 'Investigating',
        color: '#f38020',
        placeholder: 'We are currently investigating the issue.',
      };
    case 'identified':
      return {
        label: 'Identified',
        color: '#f38020',
        placeholder: 'We identified the issue are working on a solution.',
      };
    case 'monitoring':
      return {
        label: 'Monitoring',
        color: '#2c7cb0',
        placeholder: 'We implemented a solution and are monitoring the results.',
      };
    case 'resolved':
      return {
        label: 'Resolved',
        color: '#1b2a4e',
        placeholder: 'The issue has been resolved.',
      };
    default:
      return {
        label: 'Unknown',
        color: '#1b2a4e',
      };
  }
}

// TODO Get rid of below definitions or move to
// components if not shared between multiple components

export const TYPE_INCIDENT = 'incident';
export const TYPE_MAINTENANCE = 'maintenance';

export const EVENT_TYPES = {
  [TYPE_INCIDENT]: 'Incident',
  [TYPE_MAINTENANCE]: 'Scheduled Maintenance',
};

export const EVENT_STATUS_SCHEDULED = 'scheduled';
export const EVENT_STATUS_INVESTIGATING = 'investigating';
export const EVENT_STATUS_IDENTIFIED = 'identified';
export const EVENT_STATUS_MONITORING = 'monitoring';
export const EVENT_STATUS_RESOLVED = 'resolved';

export const INCIDENT_STATUS: string[] = [
  EVENT_STATUS_INVESTIGATING,
  EVENT_STATUS_IDENTIFIED,
  EVENT_STATUS_MONITORING,
  EVENT_STATUS_RESOLVED,
];

export const IMPACT_OPERATIONAL = 'operational';
export const IMPACT_DEGRADED_PERFORMANCE = 'degraded';
export const IMPACT_PARTIAL_OUTAGE = 'partial';
export const IMPACT_MAJOR_OUTAGE = 'outage';
export const IMPACT_MAINTENANCE = 'maintenance';

export interface IImpact {
  value: string;
  label: string;
  icon: string;
  iconColor: string;
}

export const IMPACT: IImpact[] = [
  {
    value: IMPACT_OPERATIONAL,
    label: 'Operational',
    icon: 'mdi-check',
    iconColor: 'success',
  },
  {
    value: IMPACT_DEGRADED_PERFORMANCE,
    label: 'Degraded Performance',
    icon: 'mdi-minus-box',
    iconColor: 'warning',
  },
  {
    value: IMPACT_PARTIAL_OUTAGE,
    label: 'Partial Outage',
    icon: 'mdi-alert',
    iconColor: 'error',
  },
  {
    value: IMPACT_MAJOR_OUTAGE,
    label: 'Major Outage',
    icon: 'mdi-cancel',
    iconColor: 'error',
  },
  {
    value: IMPACT_MAINTENANCE,
    label: 'Under Maintenance',
    icon: 'mdi-wrench',
    iconColor: 'info',
  },
];
