








import { Component, Prop, Vue } from 'vue-property-decorator';

const ALLOWED_WIDTHS = ['', 'wide', 'narrow', 'thin'];

@Component
export default class Section extends Vue {
  @Prop({ type: String, default: '', validator: (value: string) => ALLOWED_WIDTHS.includes(value) })
  protected width!: string;
}
