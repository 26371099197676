
































































import { Action, Getter, Mutation } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getStatusMetadata, INCIDENT_STATUS, TYPE_INCIDENT } from '@/constants';
import {
  IComponent,
  ICreateOrUpdateEventParams,
  ICreateOrUpdateUpdateParams,
  IEvent,
  IEventAffectedComponent,
  IPage,
  IUpdate,
  ObjectId,
  StatusType,
} from '@/api';
import capitalize from 'lodash/capitalize';
import { IToast } from '@/store/modules/toast';
import rules from '@/utils/validation';

@Component<PageEventCreate>({
  metaInfo: {
    title: 'New Incident',
  },
})
export default class PageEventCreate extends Vue {
  protected rules = rules;

  protected isFormValid = false;
  protected status = 0;
  protected title = '';
  protected body = '';
  protected affectedComponents: IEventAffectedComponent[] = [];

  @Getter
  protected page!: IPage;

  @Getter
  protected components!: IComponent[];

  @Action
  protected getPage!: (pageId: ObjectId) => void;

  @Action
  protected listComponents!: (pageId: ObjectId) => void;

  @Action
  protected createEvent!: (params: ICreateOrUpdateEventParams) => Promise<IEvent>;

  @Action
  protected createUpdate!: (params: ICreateOrUpdateUpdateParams) => Promise<IUpdate>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  protected mounted() {
    this.resetForm();
  }

  @Watch('$route', { immediate: true })
  protected onPageChanged() {
    const { pageId } = this.$route.params;
    this.getPage(pageId);
    this.listComponents(pageId);
  }

  protected resetForm() {
    this.status = 0;
    this.title = '';
    this.body = '';
    this.affectedComponents = [];
  }

  protected get statusLabels(): string[] {
    return INCIDENT_STATUS.map((i) => capitalize(i));
  }

  protected get placeholderMessage() {
    return getStatusMetadata(INCIDENT_STATUS[this.status] as StatusType).placeholder;
  }

  protected get rootComponents(): IComponent[] {
    return this.components.filter((c) => c.parent === null);
  }

  protected childComponents(parent: IComponent): IComponent[] {
    return this.components.filter((c) => c.parent === parent._id);
  }

  protected async onConfirmCreate() {
    try {
      const event = await this.createEvent({
        page: this.page._id,
        type: TYPE_INCIDENT,
        title: this.title,
      });
      await this.createUpdate({
        page: this.page._id,
        event: event._id,
        body: this.body,
        status: INCIDENT_STATUS[this.status] as StatusType,
        affectedComponents: this.affectedComponents,
        sendNotification: true,
      });
    } catch (e) {
      this.setToast({ message: 'Failed to create incident.', type: 'error' });
      return;
    }

    this.setToast({ message: 'The incident has been successfully created.' });
    this.$router.push({ name: 'page', params: { pageId: this.page._id } });
    this.resetForm();
  }
}
