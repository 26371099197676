var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.component.parent ? '' : '',attrs:{"elevation":_vm.component.parent ? 0 : undefined}},[_c('v-card-text',{class:_vm.component.parent ? 'ma-0 pa-0' : 'py-4 mb-8'},[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":_vm.metadata.color,"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.metadata.label))])]),_c('h2',{class:[_vm.component.parent ? 'subtitle-1' : 'title', _vm.darkMode ? '' : 'black--text'],domProps:{"textContent":_vm._s(_vm.component.name)}}),_c('v-spacer'),_c('dialog-component-edit',{attrs:{"component":_vm.component},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])}),_c('dialog-delete',{attrs:{"value":_vm.component,"callback":_vm.deleteComponent},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}])})],1),(_vm.component.description)?_c('p',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.component.description)}}):_vm._e(),_vm._l((_vm.children),function(child){return _c('page-component',{key:child._id,attrs:{"component":child}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }