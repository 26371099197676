import * as api from '@/api';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { get, set } from '@/utils/vuex';
import { IInviteMemberParams, IMember } from '@/api';
import { IRootState } from '@/store';

export interface IMemberState {
  member: IMember | null;
  members: IMember[];
}

const state: IMemberState = {
  member: null,
  members: [],
};

const getters: GetterTree<IMemberState, IRootState> = {
  member: get('member'),
  members: get('members'),
};

const mutations: MutationTree<IMemberState> = {
  setMember: set('member'),
  setMembers: set('members'),
};

const actions: ActionTree<IMemberState, IRootState> = {
  async listMembers(context: ActionContext<IMemberState, IRootState>, pageId?: string) {
    const pageIdentifier = pageId || context.rootState.pages.page?._id;
    if (!pageIdentifier) throw new Error('No page provided or in context');
    const members = await api.listMembers(pageIdentifier);
    context.commit('setMembers', members);
    return members;
  },
  async inviteMember(context: ActionContext<IMemberState, IRootState>, data: IInviteMemberParams) {
    const member = await api.inviteMember(data);
    context.commit('setMember', member);
    await context.dispatch('listMembers');
    return member;
  },
  async reinviteMember(context: ActionContext<IMemberState, IRootState>, memberId: string) {
    const member = await api.reinviteMember({ member: memberId });
    context.commit('setMember', member);
    await context.dispatch('listMembers');
    return member;
  },
  async deleteMember(context: ActionContext<IMemberState, IRootState>, memberId: string) {
    await api.deleteMember(memberId);
    context.commit('setMember', null);
    await context.dispatch('listMembers');
  },
};

const module: Module<IMemberState, IRootState> = {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
};

export default module;
