








































import * as api from '@/api';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ImageInput extends Vue {
  @Prop()
  protected value!: string | undefined;

  @Prop(String)
  protected accept!: string;

  @Prop(String)
  protected placeholder!: string;

  @Prop(String)
  protected label!: string;

  /** Set this to `auto` for small images (favicon) to avoid scaling it up. */
  @Prop({ type: String, default: 'contain' })
  protected imageSize!: string;

  protected async onFileUpload(e: InputEvent) {
    // https://stackoverflow.com/a/41803227
    const fileUpload = (e.target as HTMLInputElement | null)?.files || e.dataTransfer?.files;
    if (!fileUpload?.length) {
      return;
    }
    const { name } = await api.uploadFile({ file: fileUpload[0] });
    this.$emit('input', name);
  }

  protected removeImage() {
    this.$emit('input', undefined);
  }

  protected get imageStyle() {
    return { 'background-image': `url(${this.value})`, 'background-size': this.imageSize };
  }
}
