import * as api from '@/api';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { COOKIE_ATTRIBUTES, COOKIE_KEY_TOKEN } from '@/constants';
import { get, set } from '@/utils/vuex';
import { ILoginParams, IRegisterUserParams, IResetWithPasswordParams, IUserWithAbilities } from '@/api';
import { ability } from '@/auth/abilities';
import Cookies from 'js-cookie';
import { IRootState } from '@/store';

export interface IAuthState {
  me: IUserWithAbilities | null;
}

const state: IAuthState = {
  me: null,
};

const getters: GetterTree<IAuthState, IRootState> = {
  me: get('me'),
};

const mutations: MutationTree<IAuthState> = {
  setMe: set('me'),
};

const actions: ActionTree<IAuthState, IRootState> = {
  getMe: async (context: ActionContext<IAuthState, IRootState>) => {
    const user = await api.getMe({ fetch: api.makeCustomFetch({ showToast: false }) });
    ability.update(user.abilityRules);
    context.commit('setMe', user);
  },
  deleteMe: async (context: ActionContext<IAuthState, IRootState>) => {
    if (!context.state.me) {
      throw new Error('No user in context');
    }
    await api.deleteUser(context.state.me._id);
    await context.dispatch('logout');
  },
  login: async (context: ActionContext<IAuthState, IRootState>, data: ILoginParams) => {
    await context.dispatch('logout');
    const user = await api.login(data);
    Cookies.set(COOKIE_KEY_TOKEN, user.token, COOKIE_ATTRIBUTES);
    await context.dispatch('getMe');
  },
  signup: async (context: ActionContext<IAuthState, IRootState>, data: IRegisterUserParams) => {
    await context.dispatch('logout');
    const user = await api.register(data);
    Cookies.set(COOKIE_KEY_TOKEN, user.token, COOKIE_ATTRIBUTES);
    await context.dispatch('getMe');
  },
  logout: async (context: ActionContext<IAuthState, IRootState>) => {
    context.commit('setMe', null);
    ability.update([]);
    Cookies.remove(COOKIE_KEY_TOKEN, COOKIE_ATTRIBUTES);
  },
  resetPassword: async (context: ActionContext<IAuthState, IRootState>, data: IResetWithPasswordParams) => {
    await context.dispatch('logout');
    const user = await api.resetPassword(data);
    Cookies.set(COOKIE_KEY_TOKEN, user.token, COOKIE_ATTRIBUTES);
    await context.dispatch('getMe');
  },
};

const module: Module<IAuthState, IRootState> = {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
};

export default module;
