interface IConfig {
  paddleVendorId: string | null;
  paddleProductId: string | null;
  paddleEnvironment: string | null;
  sentryEnvironment: string | null;
  websiteUrl: string;
  ackeeUrl: string | null;
  ackeeDomainId: string | null;
  ackeeEventsJson: string | null;
}

const configPromise: Promise<IConfig> = (async () => {
  const response = await fetch('/config.json');
  return response.json() as Promise<IConfig>;
})();

/** Load the configuration or get the already resolved promise (once loaded). */
export async function load(): Promise<IConfig> {
  return configPromise;
}
