import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

export type VuetifyForm = Vue & {
  reset: () => void;
  resetValidation: () => void;
  validate: () => boolean;
  $el: HTMLFormElement;
};
export interface ISelectItem {
  text: string;
  value: string;
}
export type ISelectItems = ISelectItem[];

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#1b2a4e',
        background: '#f9fbfd',
        anchor: '#335eea',
      },
      dark: {
        background: '#f9fbfd',
      },
    },
  },
});
