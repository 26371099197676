






























import { Action, Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { IComponent, ObjectId } from '@/api';

@Component<PageComponents>({
  metaInfo: {
    title: 'Components',
  },
})
export default class PageComponents extends Vue {
  @Getter
  protected components!: IComponent[];

  @Action
  protected getPage!: (pageId: ObjectId) => void;

  @Action
  protected listComponents!: (pageId: ObjectId) => void;

  @Watch('$route', { immediate: true })
  protected onPageChanges() {
    const { pageId } = this.$route.params;
    this.getPage(pageId);
    this.listComponents(pageId);
  }

  get parentComponents(): IComponent[] {
    return this.components.filter((c) => !c.parent);
  }
}
