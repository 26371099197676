import { COOKIE_KEY_TOKEN } from '@/constants';
import Cookies from 'js-cookie';
import { NavigationGuard } from 'vue-router';
import store from '@/store';

export const isLoggedInGuard: NavigationGuard = async (to, _from, next) => {
  if (store.state.auth.me) {
    await store.dispatch('listPages');
    next();
    return;
  }

  const token = Cookies.get(COOKIE_KEY_TOKEN);
  const nextRoute = to.fullPath !== '/' ? to.fullPath : undefined;
  if (!token) {
    next({ name: 'login', query: { next: nextRoute } });
    return;
  }

  try {
    await store.dispatch('getMe');
    await store.dispatch('listPages');
    next();
  } catch (err: any) {
    next({ name: 'login', query: { next: nextRoute } });
  }
};
