var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-section',[_c('layout-title',{scopedSlots:_vm._u([{key:"affix",fn:function(){return [_c('dialog-member-create',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"primary","disabled":!_vm.$can('update', _vm.page) || !_vm.$can('create', { kind: 'member', page: _vm.page._id })}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v(" Invite Team Member ")],1)]}}])})]},proxy:true}])},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-account-multiple")]),_vm._v(" Members ")],1),_c('v-card',[_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.ownerAndMembers,"search":_vm.search},scopedSlots:_vm._u([{key:"item.acceptedAt",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.acceptedAt ? 'green' : 'grey lighten-2',"small":""}},[_vm._v(" mdi-circle ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('date-time',{attrs:{"value":item.createdAt}})]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" "),(item.isOwner)?_c('v-chip',{attrs:{"outlined":"","small":"","color":"primary"}},[_vm._v("owner")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"center"}},[(!item.acceptedAt)?_c('dialog-member-resend-invitation',{attrs:{"page":_vm.page,"member":item}}):_vm._e(),_c('dialog-delete',{attrs:{"value":item,"callback":_vm.deleteMember},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","small":"","icon":"","disabled":item.isOwner || !_vm.$can('delete', item)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can")])],1)]}}],null,true)})],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }