
































import { Action, Getter, Mutation } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IComponent, IPage, ObjectId } from '@/api';
import { ICreateOrUpdateComponentData } from '@/store/modules/component';
import { IToast } from '@/store/modules/toast';
import rules from '@/utils/validation';

@Component
export default class DialogComponentEdit extends Vue {
  protected rules = rules;
  protected isOpen = false;
  protected isFormValid = false;
  protected name = '';
  protected description?: string;
  protected parent?: ObjectId;

  @Prop({
    type: Object,
    default: () => null,
    required: false,
    validator: (value) => value !== null,
  })
  protected component!: IComponent;

  @Getter
  protected page!: IPage;

  @Getter
  protected components!: IComponent[];

  @Action
  protected listPages!: () => void;

  @Action
  protected updateComponent!: (data: ICreateOrUpdateComponentData) => Promise<void>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  @Watch('component', { immediate: true })
  protected onComponentChanged() {
    this.name = this.component.name;
    this.description = this.component.description;
    this.parent = this.hasChildren ? undefined : this.component.parent;
  }

  protected get rootComponents() {
    return this.components.filter((c) => !c.parent && c._id !== this.component._id);
  }

  protected get hasChildren() {
    return !!this.components.find((c) => c.parent === this.component._id);
  }

  protected async onConfirm() {
    try {
      await this.updateComponent({
        componentId: this.component._id,
        params: {
          ...this.component,
          name: this.name,
          description: this.description,
          parent: this.parent || undefined,
        },
      });
      this.isOpen = false;
      this.setToast({ message: 'The component has been updated.' });
    } catch (e) {
      this.setToast({ message: 'Failed to save changes.', type: 'error' });
    }
  }

  protected closeDialog() {
    this.isOpen = !this.isOpen;
  }
}
