


























import * as api from '@/api';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Mutation, namespace } from 'vuex-class';
import { ackeeAction } from '@/plugins/ackee';
import { IRecoverParams } from '@/api';
import { IToast } from '@/store/modules/toast';
import rules from '@/utils/validation';
import { VuetifyForm } from '@/plugins/vuetify';

@Component<RecoverPassword>({
  metaInfo: {
    title: 'Recover Password',
  },
})
export default class RecoverPassword extends Vue {
  protected readonly rules = rules;

  @Ref() protected readonly form!: VuetifyForm;

  protected data: IRecoverParams = { email: '' };

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  @namespace('loading').Getter
  protected isLoading!: boolean;

  protected async onRecover() {
    if (this.form.validate()) {
      try {
        ackeeAction('click', { key: 'recover', value: 1 });
        await api.requestPasswordReset(this.data);
        this.setToast({
          message: `Thank you! We’ll check whether an account with address ${this.data.email} exists and if so we will send an e-mail with reset instructions.`,
        });
        this.goHome();
      } catch (e) {
        this.setToast({
          message:
            'Something went wrong. Please try again later. If the issue continues to persist, please get in touch with us!',
          type: 'error',
        });
      }
    }
  }

  protected goHome() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.$router.push({ name: 'home' }).catch(() => {});
  }
}
