























































import { Action, Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { IPage, ISubscriber, ObjectId } from '@/api';
import { DataTableHeader } from 'vuetify';

@Component<PageSubscribers>({
  metaInfo: {
    title: 'Subscribers',
  },
})
export default class PageSubscribers extends Vue {
  protected search = '';
  protected headers: DataTableHeader[] = [
    { text: 'Confirmed', value: 'confirmedAt', align: 'center', sortable: false },
    { text: 'E-Mail', value: 'email', align: 'start', sortable: true },
    { text: 'Date', value: 'createdAt', align: 'center', sortable: true },
    { text: 'Actions', value: 'actions', align: 'center', sortable: false },
  ];

  @Getter
  protected page!: IPage;

  @Getter
  protected subscribers!: ISubscriber[];

  @Action
  protected getPage!: (pageId: ObjectId) => void;

  @Action
  protected listSubscribers!: (pageId: ObjectId) => void;

  @Action
  protected deleteSubscriber!: (pageId: ObjectId) => void;

  @Watch('$route', { immediate: true })
  protected onPageChanges() {
    const { pageId } = this.$route.params;
    this.getPage(pageId);
    this.listSubscribers(pageId);
  }
}
