






























import { Component, Prop, Vue } from 'vue-property-decorator';
import rules from '../utils/validation';

@Component
export default class ColorInput extends Vue {
  rules = {
    ...rules,
    // XXX We need a special hex validation that ignores the hash
    // sign as it is a prefix and added right after validation
    hex: (v: string) => !v || /^([0-9A-F]{3}){1,2}$/.test(v) || 'Invalid hex color',
  };
  menu = false;

  @Prop()
  protected value!: string | undefined;

  get text() {
    return this.value?.replace(/^#/, '').toUpperCase();
  }

  get color() {
    return this.value?.toUpperCase();
  }

  get style() {
    return {
      backgroundColor: this.value,
      cursor: 'pointer',
      height: '24px',
      width: '24px',
      borderColor: 'grey',
      borderStyle: this.isWhite() ? 'solid' : 'none',
      borderWidth: '1px',
      borderRadius: '50%',
      transition: 'border-radius 200ms ease-in-out',
    };
  }

  protected onTextChanged(text: string) {
    this.$emit('input', `#${text}`);
  }

  protected onColorChanged(color: string) {
    if (this.menu) {
      this.$emit('input', color);
    }
  }

  private isWhite(): boolean {
    return this.value?.toLowerCase() === '#ffffff';
  }
}
