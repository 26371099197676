













































import * as api from '@/api';
import * as config from '@/config';
import { Action, Mutation, namespace } from 'vuex-class';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { debounce, kebabCase } from 'lodash';
import { ICreateOrUpdatePageParams, IPage } from '@/api';
import { IToast } from '@/store/modules/toast';
import LanguageSelect from '@/components/LanguageSelect.vue';
import rules from '@/utils/validation';
import { VuetifyForm } from '@/plugins/vuetify';

@Component<PageCreate>({
  metaInfo: {
    title: 'New Status Page',
  },
  components: {
    LanguageSelect,
  },
})
export default class PageCreate extends Vue {
  protected rules = rules;

  @Ref() protected readonly form!: VuetifyForm;

  protected protocol = '';

  protected host = '';

  protected data: ICreateOrUpdatePageParams = {
    name: '',
    // space to prevent showing the label within the <input>
    slug: ' ',
    allowPageSubscribers: true,
    colorHeader: '#1b2a4e',
    hiddenFromSearch: false,
    language: 'en',
  };

  protected slugDirty = false;

  /** Async validation errors. */
  protected slugErrors: string[] = [];

  @namespace('loading').Getter
  protected isLoading!: boolean;

  @Action
  protected createPage!: (data: ICreateOrUpdatePageParams) => Promise<IPage>;

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  protected async mounted() {
    const { websiteUrl } = await config.load();
    const { host, protocol } = new URL(websiteUrl);
    this.host = host;
    this.protocol = protocol;
  }

  protected async submit() {
    if (!this.form.validate() || this.slugErrors.length > 0) {
      return;
    }

    try {
      const page = await this.createPage(this.data);
      this.setToast({ message: 'Your page has been created!' });
      this.$router.push({ name: 'page', params: { pageId: page._id } });
    } catch (e) {
      this.setToast({ message: 'Failed to create page. Please try again.', type: 'error' });
      return;
    }
  }

  @Watch('data.name')
  protected updateSlug() {
    if (this.slugDirty) {
      return;
    }
    this.data.slug = this.createSlug(this.data.name) || ' ';
  }

  @Watch('data.slug')
  protected checkSlug = debounce(() => this._checkSlug(), 100);

  /** nb: Keep separate from `checkSlug` due to `this` reference. */
  private async _checkSlug() {
    const { result } = await api.checkSlug(this.data.slug);
    if (result === 'taken') {
      this.slugErrors = ['Slug is already taken'];
    } else if (result === 'invalid') {
      this.slugErrors = ['Slug must consist of at least four lowercase, number or hyphen characters'];
    } else {
      this.slugErrors = [];
    }
  }

  private createSlug(value: string): string {
    return kebabCase(value.replace(/&/g, '-and-'));
  }
}
