





















import * as api from '@/api';
import { Action, Mutation, namespace } from 'vuex-class';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { IResetParams, IResetWithPasswordParams } from '@/api';
import { ackeeAction } from '@/plugins/ackee';
import { IToast } from '@/store/modules/toast';
import rules from '@/utils/validation';
import { VuetifyForm } from '@/plugins/vuetify';

@Component<ResetPassword>({
  metaInfo: {
    title: 'Reset Password',
  },
})
export default class ResetPassword extends Vue {
  protected readonly rules = rules;

  @Ref() protected readonly form!: VuetifyForm;

  protected data: IResetWithPasswordParams = { resetId: '', token: '', password: '' };

  protected email = '';

  @Mutation
  protected setToast!: (toast: IToast | null) => void;

  @namespace('loading').Getter
  protected isLoading!: boolean;

  @Action
  protected resetPassword!: (data: IResetWithPasswordParams) => Promise<void>;

  async created(): Promise<void> {
    this.data = {
      resetId: this.$route.params.resetId,
      token: this.$route.params.token,
      password: '',
    };
    const { resetId, token }: IResetParams = this.data;
    try {
      const { email } = await api.checkPasswordReset({ resetId, token });
      this.email = email;
    } catch (e) {
      this.setToast({
        message: 'This is not a valid recovery link. Please request a new password recovery link and try again.',
        type: 'error',
      });
      this.goHome();
    }
  }

  protected async onReset() {
    if (this.form.validate()) {
      try {
        ackeeAction('click', { key: 'reset', value: 1 });
        await this.resetPassword(this.data);
        this.goHome();
      } catch (e) {
        this.setToast({
          message:
            'Something went wrong. Please try again later. If the issue continues to persist, please get in touch with us!',
          type: 'error',
        });
      }
    }
  }

  protected goHome() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.$router.push({ name: 'home' }).catch(() => {});
  }
}
