import * as config from '../config';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import Vue from 'vue';

(async () => {
  const { sentryEnvironment } = await config.load();
  if (typeof sentryEnvironment === 'string' && sentryEnvironment.length) {
    Sentry.init({
      Vue,
      dsn: 'https://5843d3f6d3474d91afbcd5e45035c476@o763015.ingest.sentry.io/5793736',
      environment: sentryEnvironment,
      integrations: [new Integrations.BrowserTracing()],
      attachProps: true,
      logErrors: true,
      tracesSampleRate: 1.0,
    });
  }
})().catch(() => {
  // do nothing
});
