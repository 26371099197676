




























































import { Component, Vue } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { IPage, IUserWithAbilities } from '@/api';
import { Location } from 'vue-router';

interface INavigationItem {
  to: (page: IPage) => Location;
  icon: string;
  text: string;
}

@Component
export default class Header extends Vue {
  protected readonly navigationItems: INavigationItem[] = [
    {
      to: ({ _id }: IPage) => ({ name: 'page/events', params: { pageId: _id } }),
      icon: 'mdi-list-status',
      text: 'Incidents',
    },
    {
      to: ({ _id }: IPage) => ({ name: 'page/components', params: { pageId: _id } }),
      icon: 'mdi-package-variant-closed',
      text: 'Components',
    },
    {
      to: ({ _id }: IPage) => ({ name: 'page/subscribers', params: { pageId: _id } }),
      icon: 'mdi-bell',
      text: 'Subscribers',
    },
    {
      to: ({ _id }: IPage) => ({ name: 'page/members', params: { pageId: _id } }),
      icon: 'mdi-account-multiple',
      text: 'Members',
    },
    {
      to: ({ _id }: IPage) => ({ name: 'page/settings', params: { pageId: _id } }),
      icon: 'mdi-cog',
      text: 'Settings',
    },
  ];

  @Getter
  protected page!: IPage | null;

  @Getter
  protected me!: IUserWithAbilities;

  @namespace('loading').Getter
  protected isLoading!: boolean;
}
