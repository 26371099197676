import * as api from '@/api';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { get, set } from '@/utils/vuex';
import { ICreateOrUpdateUpdateParams, IUpdate } from '@/api';
import { IRootState } from '@/store';

export interface ICreateOrUpdateUpdateData {
  updateId: string;
  params: ICreateOrUpdateUpdateParams;
}

export interface IUpdateState {
  update: IUpdate | null;
  updates: IUpdate[];
}

const state: IUpdateState = {
  update: null,
  updates: [],
};

const getters: GetterTree<IUpdateState, IRootState> = {
  update: get('update'),
  updates: get('updates'),
};

const mutations: MutationTree<IUpdateState> = {
  setUpdate: set('update'),
  setUpdates: set('updates'),
};

const actions: ActionTree<IUpdateState, IRootState> = {
  async listUpdates(context: ActionContext<IUpdateState, IRootState>, eventId?: string): Promise<IUpdate[]> {
    const eventIdentifer = eventId || context.rootState.events.event?._id;
    if (!eventIdentifer) throw new Error('No event provided or in context');
    const updates = await api.listUpdates(eventIdentifer);
    context.commit('setUpdates', updates);
    return updates;
  },
  async getUpdate(context: ActionContext<IUpdateState, IRootState>, updateId: string): Promise<IUpdate> {
    const update = await api.getUpdate(updateId);
    context.commit('setUpdate', update);
    return update;
  },
  async createUpdate(context: ActionContext<IUpdateState, IRootState>, data: ICreateOrUpdateUpdateParams) {
    const update = await api.createUpdate(data);
    context.commit('setUpdate', update);
    await context.dispatch('listUpdates');
    return update;
  },
  async updateUpdate(context: ActionContext<IUpdateState, IRootState>, data: ICreateOrUpdateUpdateData) {
    const update = await api.updateUpdate(data.updateId, data.params);
    context.commit('setUpdate', update);
    await context.dispatch('listUpdates');
    return update;
  },
  async deleteUpdate(context: ActionContext<IUpdateState, IRootState>, updateId: string) {
    await api.deleteUpdate(updateId);
    context.commit('setUpdate', null);
    await context.dispatch('listUpdates');
  },
};

const update: Module<IUpdateState, IRootState> = {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
};

export default update;
