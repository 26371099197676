













import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component<App>({
  metaInfo: {
    titleTemplate: '%s | PageFate',
  },
})
export default class App extends Vue {
  @Getter
  protected darkMode!: boolean;

  @Watch('darkMode', { immediate: true })
  protected onDarkModeChanged() {
    this.$vuetify.theme.dark = this.darkMode;
  }

  protected get background() {
    const theme = this.$vuetify.theme.dark ? 'dark' : 'light';
    return this.$vuetify.theme.themes[theme].background;
  }
}
